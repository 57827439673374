import React, { forwardRef, ReactNode } from 'react'

import { Swiper, Pagination } from 'swiper'
import { Swiper as Slider, SwiperProps } from 'swiper/react/swiper-react.js'
import { css } from 'styled-components'

import { styled } from 'src/ui/theme'
import { SwiperRefComponent } from 'src/ui/atoms'

import 'swiper/swiper-bundle.css'

const icon = `<svg width="24" height="24" viewBox="0 0 120 120" >
  <circle
      cx="60"
      cy="60"
      r="50"
      fill="none"
      stroke="#FFFFFF"
      stroke-width="6"
      opacity="0.3"
  />
  <circle
      id="spinner"
      cx="60"
      cy="60"
      r="50"
      transform="rotate(-90 60 60)"
      fill="none"
      stroke-dashoffset="314"
      stroke-dasharray="314"
      stroke="#FFFFFF"
      stroke-width="6"
  />
</svg>`

const Container = styled.div<{ customPagination?: boolean }>`
  ${({ customPagination }) =>
    !customPagination &&
    css`
      .swiper-pagination {
        position: static;
        display: flex !important;
        justify-content: start;
        margin-top: 28px;

        button.swiper-pagination-bullet {
          margin: 5px;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: ${({ theme }) => theme.colors.gray.primary};
          color: transparent;
          outline: none;
          cursor: pointer;

          &:hover {
            background: ${({ theme }) => theme.colors.brand.secondary};
          }
        }

        button.swiper-pagination-bullet-active {
          background: ${({ theme }) => theme.colors.brand.primary};
        }
      }
    `}
`

type Props = {
  children: ReactNode
  settings?: SwiperProps
  className?: string
  customPagination?: boolean
  onSwipe?: () => void
}

export const SwiperSlider = forwardRef<Swiper, Props>(
  ({ children, settings, className, customPagination, onSwipe }, ref) => {
    const defaultSettings = {
      slidesPerView: 1,
      slidesToScroll: 1,
      speed: 200,
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index: number, className: string) => {
          return `<button class="${className}">${
            customPagination ? icon : null
          }</button>`
        },
      },
      autoHeight: true,
      onSwipe: onSwipe,
    }

    const modules = settings?.modules
      ? [...defaultSettings.modules, ...settings.modules]
      : defaultSettings.modules

    const resultSettings = { ...defaultSettings, ...settings, modules }

    return (
      <Container
        className={className}
        customPagination={Boolean(customPagination)}
      >
        <Slider {...resultSettings}>
          {children}

          <SwiperRefComponent ref={ref} />

          {!customPagination ? <div className="swiper-pagination"></div> : null}
        </Slider>
      </Container>
    )
  }
)
