import { ArrowDown } from './arrow-down'
import { ArrowLeft } from './arrow-left'
import { ArrowRight } from './arrow-right'
import { ArrowUp } from './arrow-up'
import { City } from './city'
import { Close } from './close'
import { Menu } from './menu'
import { Search } from './search'
import { SocialBe } from './social-be'
import { SocialDrib } from './social-drib'
import { SocialFb } from './social-fb'
import { SocialInst } from './social-inst'
import { SocialTg } from './social-tg'
import { SocialVk } from './social-vk'
import { Upload } from './upload'
import { Check } from './check'
import { Analytics } from './analytics'
import { Android } from './android'
import { Backend } from './backend'
import { Design } from './design'
import { Frontend } from './frontend'
import { Ios } from './ios'
import { ProjectManagement } from './project-management'
import { QualityAssurance } from './quality-assurance'
import { TortuIcon } from './tortu-icon'
import { SocialTikTok } from './social-tiktok'
import { SocialIn } from './social-in'
import { PopularSearch } from './popular-search'
import { CommunicationSend } from './communication-send'
import { Watch } from './watch'
import { CashMoney } from './cash-money'
import { FullRightArrow } from './full-right-arrow'
import { Bullet } from './bullet'
import { Rub } from './rub'
import { Recruiting } from './recruiting'
import { VoiceUi } from './voice-ui'
import { RbkIcon } from './rbk'
import { HhIcon } from './hh'
import { LinkIcon } from './link'
import { ForbesIcon } from './forbes'

export const Icons = {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  City,
  Close,
  Menu,
  Search,
  SocialBe,
  SocialDrib,
  LinkIcon,
  SocialFb,
  SocialInst,
  SocialTg,
  SocialVk,
  Upload,
  Check,
  Analytics,
  Android,
  Backend,
  Design,
  Frontend,
  Ios,
  ProjectManagement,
  QualityAssurance,
  TortuIcon,
  SocialTikTok,
  SocialIn,
  PopularSearch,
  CommunicationSend,
  Watch,
  CashMoney,
  FullRightArrow,
  Bullet,
  Rub,
  Recruiting,
  VoiceUi,
  RbkIcon,
  ForbesIcon,
  HhIcon,
}
