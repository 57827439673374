import { styled } from 'src/ui/theme'
import { keyframes } from 'styled-components'

const rendergrey = keyframes`
  0% {
    stroke-dasharray: 0 314;
    stroke-dashoffset: 314;
  }

  100% {
    stroke-dasharray: 314 0;
    stroke-dashoffset: 314;
  }`

export const CustomSliderContainer = styled.div`
  .swiper-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 64px !important;
    z-index: 1000;
  }

  button.swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    margin: 0 3px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    opacity: 1 !important;
  }

  button.swiper-pagination-bullet svg {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  button.swiper-pagination-bullet svg circle#spinner {
    animation-name: ${rendergrey};
    animation-duration: 3s;
    stroke-dasharray: 314 0;
    stroke-dashoffset: 314;
    animation-fill-mode: forwards;
    stroke-linecap: round;
  }

  button.swiper-pagination-bullet::after {
    position: relative;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.3;
  }

  button.swiper-pagination-bullet-active::after {
    opacity: 1;
  }

  button.swiper-pagination-bullet-active svg {
    display: block;
  }
`
