import React from 'react'
import { Autoplay, EffectFade } from 'swiper'
import { SwiperProps } from 'swiper/react/swiper-react.js'

export const sliderInMainSettings: SwiperProps = {
  modules: [EffectFade, Autoplay],
  effect: 'fade',
  speed: 100,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
}
