import React from 'react'

export const RbkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32ZM45.364 16H16v29.275L45.364 16ZM48 48H18.637L48 18.695V48Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width={64} height={64} fill="#fff" rx={32} />
        </clipPath>
      </defs>
    </svg>
  )
}
