import React from 'react'

export const HhIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M32 64.002c17.672 0 32-14.317 32-32.002C64 14.33 49.673 0 32 0 14.33 0 0 14.33 0 32c0 17.685 14.33 32.002 32 32.002ZM18.036 27.495v-10.98h-5.192v28.452h5.186l.006-10.026c-.006-1.682.152-2.954.469-3.813.312-.861.805-1.504 1.491-1.934.672-.426 1.444-.642 2.302-.642.753 0 1.377.166 1.875.503.5.33.846.776 1.044 1.344.198.565.291 1.895.291 3.992v10.575h5.195V33.225c0-1.797-.089-3.122-.267-3.972a6.33 6.33 0 0 0-.999-2.34c-.486-.702-1.208-1.281-2.171-1.731-.961-.456-2.034-.683-3.23-.683-2.325 0-4.33 1.004-6 2.996Zm20.453 0v-10.98H33.3v28.452h5.189V34.94c0-1.682.158-2.954.469-3.813.316-.861.81-1.504 1.488-1.934.672-.426 1.444-.642 2.304-.642.752 0 1.38.166 1.875.503.502.33.847.776 1.045 1.344.199.565.296 1.895.296 3.992v10.575h5.19V33.225c0-1.797-.09-3.122-.269-3.972a6.369 6.369 0 0 0-.998-2.34c-.484-.702-1.208-1.281-2.167-1.731-.965-.456-2.043-.683-3.236-.683-2.323 0-4.326 1.004-5.997 2.996Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h64v64.002H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
