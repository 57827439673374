import React from 'react'

export const LinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14.25 4a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-1.5 0V5.81l-7.72 7.72a.75.75 0 1 1-1.06-1.06l7.72-7.72H15a.75.75 0 0 1-.75-.75ZM4.055 6.055A2.75 2.75 0 0 1 6 5.25h6a.75.75 0 0 1 0 1.5H6A1.25 1.25 0 0 0 4.75 8v10A1.25 1.25 0 0 0 6 19.25h10A1.25 1.25 0 0 0 17.25 18v-6a.75.75 0 0 1 1.5 0v6A2.75 2.75 0 0 1 16 20.75H6A2.75 2.75 0 0 1 3.25 18V8c0-.73.29-1.429.805-1.945Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
